import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    Row,
    Select,
    Table,
    Typography,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import clienteAxios from 'config/axios';
import { urls } from 'constants/index';
import ABMLayout from '../../../../layouts/ABMLayout';
import useOrdersReport from 'components/hooks/useOrdersReport';
import useRouterQuery from 'hooks/useRouterQueries';
import classes from './Suscriptions.module.css';
import {
    formatoGuaranies,
    formatoNumeros,
} from '../../../utils-components/Number';
import { formatoHora } from 'components/utils-components/Date/index';
import { message, Spin } from '../../../../../node_modules/antd/lib/index';

const { Text } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

const MONCHIS_ORDER_STATES = [
    { id: 1, name: 'Pendiente' },
    { id: 2, name: 'Cancelada' },
    { id: 6, name: 'Entregada' },
];

const MONCHIS_FRANCHISE_ID =
    process.env.REACT_APP_NODE_ENV === 'dev' ? 3042 : 3197;

const Subscriptions = () => {
    const { createQueryParams } = useRouterQuery();
    const history = useHistory();
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [isDownloading, setIsDownloading] = useState(false);
    const [isDateRangeSelected, setIsDateRangeSelected] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 20,
    });
    const [subscriptionsReportForm] = Form.useForm();

    const {
        loadingOrders,
        getOrdersStates,
        getDeliveryTypes,
        paymentTypes,
        getPaymentTypes,
        getOrdersList,
        ordersTable,
        summaryData,
    } = useOrdersReport();

    const ordersSummary = (
        <Card className={classes.summaryCard}>
            <Row className={classes.summary}>
                <Col className={classes.colsSummary}>
                    <Text strong className={classes.totalOrders}>
                        {summaryData?.count
                            ? formatoNumeros(Number(summaryData?.count))
                            : '0'}
                    </Text>
                    <Text>pedidos</Text>
                </Col>
                <Divider type="vertical" className={classes.dividers} />
                <Col className={classes.colsSummary}>
                    <Text strong className={classes.totalBilling}>
                        {summaryData?.total
                            ? formatoGuaranies(Number(summaryData?.total))
                            : '0'}
                    </Text>
                    <Text>Total</Text>
                </Col>
            </Row>
        </Card>
    );

    useEffect(() => {
        getOrdersStates();
        getDeliveryTypes();
        getPaymentTypes();

        const savedParams = sessionStorage.getItem('lastSearchParams');
        if (savedParams) {
            const params = JSON.parse(savedParams);

            subscriptionsReportForm.setFieldsValue({
                orderState: params.orderState || 'Todos',
                paymentType: params.paymentType || 'Todos',
                dates: params.dates
                    ? [moment(params.dates[0]), moment(params.dates[1])]
                    : undefined,
            });

            setStartDate(params.dates ? params.dates[0] : '');
            setEndDate(params.dates ? params.dates[1] : '');

            getOrdersList(params);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dateRange = (valor) => {
        if (valor?.length === 2) {
            setStartDate(valor[0]?.format('YYYY-MM-DD') || '');
            setEndDate(valor[1]?.format('YYYY-MM-DD') || '');
            setIsDateRangeSelected(true);
        } else {
            setStartDate('');
            setEndDate('');
            setIsDateRangeSelected(false);
        }
    };

    const handleSubmit = (values) => {
        const params = {
            ...values,
            orderState:
                values.orderState === 'Todos' ? undefined : values.orderState,
            paymentType:
                values.paymentType === 'Todos' ? undefined : values.paymentType,
            franchiseId: MONCHIS_FRANCHISE_ID,
            page: 1,
            items_per_page: pagination.pageSize,
        };

        sessionStorage.setItem('lastSearchParams', JSON.stringify(params));

        setPagination((prev) => ({
            ...prev,
            current: 1,
        }));

        getOrdersList(params);
    };

    const onTableChange = (page, pageSize) => {
        setPagination({
            current: page,
            pageSize,
        });

        const params = {
            orderState:
                subscriptionsReportForm.getFieldValue('orderState') === 'Todos'
                    ? undefined
                    : subscriptionsReportForm.getFieldValue('orderState'),
            dates: subscriptionsReportForm.getFieldValue('dates'),
            franchiseId: MONCHIS_FRANCHISE_ID,
            page,
            items_per_page: pageSize,
        };

        getOrdersList(params);
    };

    // TABLE

    const ordersReportTable = ordersTable?.rows?.map((item) => ({
        nroOrden: item?.id,
        cliente: `${item?.user?.first_name} ${item?.user?.last_name}`,
        franquicia: item?.branch?.frsnchise?.name,
        sucursal: item?.branch?.name,
        conDriver: item?.driver_request || '-',
        categoria: item?.branch?.frsnchise?.category,
        tipoEntrega: item?.delivery_type_name,
        costoOrden: formatoGuaranies(item?.total),
        precioFinal: formatoGuaranies(
            parseFloat(item?.total || 0) +
                parseFloat(item?.delivery_price || 0),
        ),
        tipoDePago: item?.payment_type,
        fecha: moment(item?.confirmed_at).format('DD/MM/YYYY'),
        hora: formatoHora(item?.confirmed_at),
        order_id: item?.id,
        estado: item?.status,
    }));

    const columns = [
        {
            title: 'Nro. orden',
            dataIndex: 'nroOrden',
            key: 'nroOrden',
            fixed: 'left',
        },
        {
            title: 'Cliente',
            dataIndex: 'cliente',
            key: 'cliente',
            fixed: 'left',
        },
        {
            title: 'Franquicia',
            dataIndex: 'franquicia',
            key: 'franquicia',
        },
        {
            title: 'Categoría',
            dataIndex: 'categoria',
            key: 'categoria',
        },
        {
            title: 'Costo orden',
            dataIndex: 'costoOrden',
            key: 'costoOrden',
        },
        {
            title: 'Precio final',
            dataIndex: 'precioFinal',
            key: 'precioFinal',
        },
        {
            title: 'Tipo de pago',
            dataIndex: 'tipoDePago',
            key: 'tipoDePago',
        },
        {
            title: 'Fecha',
            dataIndex: 'fecha',
            key: 'fecha',
        },
        {
            title: 'Hora',
            dataIndex: 'hora',
            key: 'hora',
        },
        {
            title: 'Estado',
            dataIndex: 'estado',
            key: 'estado',
        },
        {
            title: 'Acciones',
            dataIndex: 'actions',
            key: 'actions',
            fixed: 'right',
            align: 'center',
            render: (_elem, data) => (
                <Button
                    shape="circle"
                    icon={<SearchOutlined />}
                    onClick={() => viewOrderHandler(data)}
                />
            ),
        },
    ];

    const viewOrderHandler = (item) => {
        const orderId = item?.order_id;
        const queryParams = createQueryParams({ order_id: orderId });
        history.push(
            `/reportes/ventas/detalle-transaccion/ver-orden${queryParams}`,
        );
    };

    const downloadInvoices = async () => {
        const formValues = subscriptionsReportForm.getFieldsValue();
        const selectedDates = formValues.dates || [];

        const startDateFormatted = selectedDates[0].format('YYYY-MM-DD');
        const endDateFormatted = selectedDates[1].format('YYYY-MM-DD');
        setIsDownloading(true);
        try {
            const response = await clienteAxios.get(
                `${urls.REACT_APP_BACKEND_URL_D}/excel_invoice_subscription?date_start=${startDateFormatted}&date_end=${endDateFormatted}`,
            );
            window.open(response.data?.data?.link);
        } catch (error) {
            message.error(
                error.response.data.message || 'Error al descargar facturas',
            );
        } finally {
            setIsDownloading(false);
        }
    };

    return (
        <ABMLayout titulo="Reporte de órdenes de suscripciones">
            <Card>
                <Form
                    layout="vertical"
                    form={subscriptionsReportForm}
                    onFinish={handleSubmit}>
                    <Row gutter={[16, 16]}>
                        <Col span={6}>
                            <Form.Item
                                name="dates"
                                label="Rango de fechas"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Este campo no puede ir vacío',
                                    },
                                ]}>
                                <RangePicker
                                    onChange={dateRange}
                                    className={classes.dateRange}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                name="orderState"
                                label="Estado"
                                initialValue="Todos">
                                <Select>
                                    <Option key="all" value="Todos">
                                        Todos
                                    </Option>
                                    {MONCHIS_ORDER_STATES.map((state) => (
                                        <Option key={state.id} value={state.id}>
                                            {state.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item
                                name="paymentType"
                                label="Tipo de pago"
                                initialValue="Todos">
                                <Select>
                                    <Option key="all" value="Todos">
                                        Todos
                                    </Option>
                                    {paymentTypes?.map((type) => (
                                        <Option key={type.id} value={type.id}>
                                            {type.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={3}>
                            <Form.Item name="clientID" label="ID de cliente">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item name="orderNumber" label="Nro. de Orden">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item>
                                <Button
                                    className={classes.marginBottom}
                                    type="primary"
                                    disabled={!(startDate && endDate)}
                                    htmlType="submit">
                                    Buscar
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Button
                                className={classes.marginBottom}
                                type="primary"
                                onClick={downloadInvoices}
                                disabled={
                                    !isDateRangeSelected || isDownloading
                                }>
                                {isDownloading ? (
                                    <Spin size="small" />
                                ) : (
                                    'Descargar Facturas'
                                )}
                            </Button>
                        </Col>
                    </Row>
                </Form>
                {ordersSummary}
                <Row>
                    <Col span={24}>
                        <Table
                            dataSource={ordersReportTable}
                            columns={columns}
                            rowKey={(record) => record.nroOrden}
                            pagination={{
                                current: pagination.current,
                                pageSize: pagination.pageSize,
                                total: ordersTable?.total || 0,
                                onChange: onTableChange,
                                showSizeChanger: false,
                            }}
                            size="small"
                            scroll={{ x: 1600 }}
                            tableLayout="fixed"
                            loading={loadingOrders}
                        />
                    </Col>
                </Row>
            </Card>
        </ABMLayout>
    );
};

export default Subscriptions;
